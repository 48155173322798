import React, { useEffect, useState } from 'react';
import { Text, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { TimePickerAccessibility } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { AccessibilityHtmlTags } from '../../../../../utils/accessibility/constants';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import { FlowElements, useFlow } from '../../../Hooks/useFlow';
import { SlotsStatus } from '../../../../../types/types';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';
import TimeSlotsNotification from '../../TimeSlotsNotification/TimeSlotsNotification';
import { TimeSlotsNotificationViewModel } from '../../../ViewModel/timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';
import { classes, st } from './TimePicker.st.css';
import { DataHooks } from './constants';
import { AvailabilityPlugin } from '../../AvailabilityPlugin/AvailabilityPlugin';

export type TimePickerProps = {
  status: SlotsStatus;
  date?: string;
  timeSlotsNotificationViewModel?: TimeSlotsNotificationViewModel;
  accessibility: TimePickerAccessibility;
  availableSlotsComponent: React.ReactNode;
  noAvailableSlotsComponent: React.ReactNode;
};

const SelectedDate: React.FC<{ date: string }> = ({ date }): JSX.Element => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isCalendarA11YChangesEnabled = experiments.enabled(
    'specs.bookings.calendarA11YChanges',
  );
  return (
    <text id="selected-date" data-hook="{DataHooks.SELECTED_DATE}" className="{st(classes.formattedSelectedDate)}" tagName="{AccessibilityHtmlTags.Paragraph}" aria-hidden="true">
      {isCalendarA11YChangesEnabled
        ? t('app.time-picker.header', { date })
        : date}
    </text>
  );
};

const Loader: React.FC = () => {
  return (
    <div data-hook="{DataHooks.LOADER}" className="{classes.spinnerWrapper}">
      <spinnerwrapper className="{classes.spinner}" diameter="{24}" isCentered="{true}" type="{SpinnerTypes.slim}"></spinnerwrapper>
    </div>
  );
};

const TimePicker: React.FC<timepickerprops> = ({
  status,
  date,
  timeSlotsNotificationViewModel,
  accessibility,
  availableSlotsComponent,
  noAvailableSlotsComponent,
}) => {
  const { isMobile, isRTL } = useEnvironment();
  const timePickerRef = useFlow(FlowElements.TIME_PICKER);
  const [srOnlyAnnouncement, setSROnlyAnnouncement] = useState('');

  useEffect(() => {
    setSROnlyAnnouncement('');
    if (status !== SlotsStatus.LOADING) {
      setTimeout(
        () =>
          setSROnlyAnnouncement(accessibility.onTimePickerLoadedAnnouncement),
        300,
      );
    }
  }, [status, accessibility.onTimePickerLoadedAnnouncement]);

  return (
    <div className="{st(classes.root," {="" isMobile,="" isRTL,="" })}="" ref="{timePickerRef}">
      <sronly id="announcement" role="alert" data-hook="{DataHooks.SR_ONLY_ANNOUNCEMENT}">
        {srOnlyAnnouncement}
      </sronly>
      <div data-hook="{DataHooks.TIME_PICKER}" role="group" aria-labelledby="selected-date">
        <selecteddate date="{date!}"></selecteddate>
        {status == SlotsStatus.LOADING ? <loader></loader> : null}
        {status === SlotsStatus.AVAILABLE_SLOTS ? (
          <div>
            <timeslotsnotification viewModel="{timeSlotsNotificationViewModel}"></timeslotsnotification>
            {availableSlotsComponent}
          </div>
        ) : null}
        {status === SlotsStatus.NO_AVAILABLE_SLOTS
          ? noAvailableSlotsComponent
          : null}
      </div>
      <availabilityplugin></availabilityplugin>
    </div>
  );
};

export default TimePicker;
</timepickerprops>